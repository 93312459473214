import { IDBPDatabase, openDB } from 'idb';
import { now } from 'lodash';
import { useEffect, useState } from 'react';

import { LookupItemsQuery } from '@spinach-shared/schemas';
import { MeetingSelection, ViewableTranscriptLineData } from '@spinach-shared/types';

import { getSearchData } from '../../../../apis/getSearchData';
import { GlobalSearchData, GlobalSearchDataItem, SummaryWrapper } from '../globalSearch/globalSearchTypes';
import { consolidateTranscript } from '../globalSearch/searchUtils';

const DB_NAME = 'cacheDB';
const STORE_NAME = 'cacheStore';
const TTL = 5 * 24 * 60 * 60 * 1000; // 5 days in milliseconds

async function initDB() {
    const db = await openDB(DB_NAME, 1, {
        upgrade(db) {
            const store = db.createObjectStore(STORE_NAME, { keyPath: 'key' });
            store.createIndex('expiry', 'expiry', { unique: false });
        },
    });
    return db;
}

async function setItem(db: IDBPDatabase, key: string, value: string) {
    const expiry = Date.now() + TTL;
    await db.put(STORE_NAME, { key, value, expiry });
}

async function getItem(db: IDBPDatabase, key: string) {
    const item = await db.get(STORE_NAME, key);
    if (item && item.expiry > Date.now()) {
        return item.value;
    } else if (item) {
        await db.delete(STORE_NAME, key);
    }
    return null;
}

async function clearExpiredItems(db: IDBPDatabase) {
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    const index = store.index('expiry');
    const now = Date.now();
    let cursor = await index.openCursor();
    while (cursor) {
        if (cursor.value.expiry <= now) {
            await cursor.delete();
        }
        cursor = await cursor.continue();
    }
    await tx.done;
}

const searchLimit = 100;

export const useGlobalSearchData = (meetings: MeetingSelection[]) => {
    const [data, setData] = useState<GlobalSearchData | undefined>(undefined);
    const [isError, setIsError] = useState(false);
    const cachedKnownBotIds = meetings
        .map(({ botId }) => botId)
        .sort()
        .join(',');

    useEffect(() => {
        if (!meetings.length) {
            return;
        }
        (async () => {
            try {
                const allMettingsToBeSearched = [...meetings]
                    .sort((b, a) => a.createdAt.getTime() - b.createdAt.getTime())
                    .slice(0, searchLimit);
                const db = await initDB();
                try {
                    await clearExpiredItems(db);
                } catch (e) {}
                const missingItems: LookupItemsQuery = [];
                const serialItems = await Promise.all(
                    allMettingsToBeSearched.map(async ({ seriesId, botId, createdAt, ticket }) => {
                        const cacheKey = `${seriesId}-${botId}`;
                        let transcript: ViewableTranscriptLineData[] | null | undefined;
                        let summary: SummaryWrapper | null | undefined;

                        try {
                            transcript = JSON.parse((await getItem(db, `${cacheKey}-transcript`)) as string);
                        } catch (e) {}

                        try {
                            summary = JSON.parse((await getItem(db, `${cacheKey}-summary`)) as string);
                        } catch (e) {}

                        if (!transcript || !summary) {
                            if (ticket.expiration < now()) {
                                window.location.reload();
                            } else {
                                missingItems.push({ seriesId, botId, ticket });
                            }
                        }
                        return {
                            seriesId,
                            botId,
                            transcript: consolidateTranscript(transcript),
                            summary,
                            createdAt,
                        };
                    })
                );

                const otherResults = await getSearchData(missingItems);
                const others = otherResults.items.map(({ seriesId, botId, transcript, summary }) => {
                    const cacheKey = `${seriesId}-${botId}`;
                    setItem(db, `${cacheKey}-transcript`, JSON.stringify(transcript));
                    setItem(db, `${cacheKey}-summary`, JSON.stringify(summary));
                    return {
                        seriesId,
                        botId,
                        transcript: consolidateTranscript(transcript),
                        summary,
                        createdAt: new Date(summary.meetingTime),
                    };
                });
                const all = [...serialItems, ...others];
                setData(all.filter((d): d is GlobalSearchDataItem => !!d.transcript && !!d.summary));
            } catch (e) {
                setIsError(true);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(cachedKnownBotIds)]);
    const isLoading = meetings.length > 0 && !data && !isError;
    return { data, isError, isLoading };
};
