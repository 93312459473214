import { DashboardSection } from '@spinach-shared/constants';
import { ClientUser } from '@spinach-shared/models';
import { ClientPlatform, WebUrlQuery } from '@spinach-shared/types';

import { isProductionStage, isStagingStage, isTestStage } from '.';

// TODO: update when chrome comes in between subdomains
export function getWebAppHost(): string {
    const url = isProductionStage()
        ? 'https://app.spinach.io'
        : isStagingStage()
        ? 'https://staging.app.spinach.io'
        : isTestStage() || process.env.REACT_APP_PLATFORM === ClientPlatform.Desktop
        ? 'https://internal.app.spinach.io'
        : 'http://localhost:3001';

    return url;
}

export type AiDeepLinkIds = {
    botId: string;
    seriesId: string;
    vProps?: ViralityLandingPageV1Props;
};

export function getSharedLink(code: string): string {
    const params = new URLSearchParams({
        [WebUrlQuery.Meeting]: code,
    });

    return `${getWebAppHost()}?${params.toString()}`;
}

export function getAiUserSettingsUrl(): string {
    const params = new URLSearchParams({
        [WebUrlQuery.Section]: DashboardSection.Integrations,
        [WebUrlQuery.Redirect]: '/ai/home',
    });

    return `${getWebAppHost()}?${params.toString()}`;
}

export type ViralityLandingPageV1Props = {
    hasLoggedIn: boolean;
    recipientFirstName?: string;
    hostFirstName?: string;
    meetingTitle: string;
};

export function mapViralityV1Props(
    recipient: ClientUser,
    host: ClientUser,
    meetingTitle: string
): ViralityLandingPageV1Props | undefined {
    if (!recipient.isEnabledForViralityLandingV1) {
        return undefined;
    }

    if (host.spinachUserId === recipient.spinachUserId) {
        return undefined;
    }

    if (recipient.metadata.hasLoggedIn) {
        return undefined;
    }

    const vProps: ViralityLandingPageV1Props = {
        meetingTitle,
        hasLoggedIn: !!recipient.metadata.hasLoggedIn,
        hostFirstName: host.firstName,
        recipientFirstName: recipient.firstName,
    };

    return vProps;
}

export function getViralityLandingPageV1Query(options: ViralityLandingPageV1Props): string {
    const { hasLoggedIn } = options;

    try {
        if (!hasLoggedIn) {
            const stringified = JSON.stringify(options);
            const encoded = encodeURIComponent(stringified);
            const codified = btoa(encoded);
            return codified;
        } else {
            return '';
        }
    } catch (error) {
        return '';
    }
}

export function getTranscriptDeepLink({ botId, seriesId, vProps }: AiDeepLinkIds): string {
    const viralityQuery = vProps ? getViralityLandingPageV1Query(vProps) : '';

    const params = new URLSearchParams({
        [WebUrlQuery.Section]: DashboardSection.Transcripts,
        [WebUrlQuery.Redirect]: '/ai/home',
        [WebUrlQuery.SeriesId]: seriesId,
        [WebUrlQuery.BotId]: botId,
    });

    if (viralityQuery) {
        params.append(WebUrlQuery.ViralityExperiment, viralityQuery);
    }

    return `${getWebAppHost()}?${params.toString()}`;
}

export function getSummaryDeepLink({ botId, seriesId, vProps }: AiDeepLinkIds): string {
    const viralityQuery = vProps ? getViralityLandingPageV1Query(vProps) : '';

    const params = new URLSearchParams({
        [WebUrlQuery.Section]: DashboardSection.Summaries,
        [WebUrlQuery.Redirect]: '/ai/home',
        [WebUrlQuery.SeriesId]: seriesId,
        [WebUrlQuery.BotId]: botId,
    });

    if (viralityQuery) {
        params.append(WebUrlQuery.ViralityExperiment, viralityQuery);
    }

    return `${getWebAppHost()}?${params.toString()}`;
}

export function getAsyncVideoDeepLink({ botId, seriesId, vProps }: AiDeepLinkIds): string {
    const viralityQuery = vProps ? getViralityLandingPageV1Query(vProps) : '';

    const params = new URLSearchParams({
        [WebUrlQuery.Section]: DashboardSection.Video,
        [WebUrlQuery.Redirect]: '/ai/home',
        [WebUrlQuery.SeriesId]: seriesId,
        [WebUrlQuery.BotId]: botId,
    });

    if (viralityQuery) {
        params.append(WebUrlQuery.ViralityExperiment, viralityQuery);
    }

    return `${getWebAppHost()}?${params.toString()}`;
}

export function getBillingAccountDeepLink(): string {
    const params = new URLSearchParams({
        [WebUrlQuery.Section]: DashboardSection.Account,
        [WebUrlQuery.Redirect]: '/ai/home',
    });

    return `${getWebAppHost()}?${params.toString()}`;
}

export function getCalendarMeetingsDeepLink(): string {
    const params = new URLSearchParams({
        [WebUrlQuery.Section]: DashboardSection.Meetings,
        [WebUrlQuery.Redirect]: '/ai/home',
    });

    return `${getWebAppHost()}?${params.toString()}`;
}

export function getActionItemsDeepLink({ botId, seriesId, vProps }: AiDeepLinkIds): string {
    const viralityQuery = vProps ? getViralityLandingPageV1Query(vProps) : '';

    const params = new URLSearchParams({
        [WebUrlQuery.Section]: DashboardSection.Actions,
        [WebUrlQuery.Redirect]: '/ai/home',
        [WebUrlQuery.SeriesId]: seriesId,
        [WebUrlQuery.BotId]: botId,
    });

    if (viralityQuery) {
        params.append(WebUrlQuery.ViralityExperiment, viralityQuery);
    }

    return `${getWebAppHost()}?${params.toString()}`;
}

export type ExternalClickTrackPayload = Record<string, any> & {
    experiment: string;
    distinct_id: string;
    redirect_url: string;
    cohort?: string | null;
};

export function getEmailCtaMetadataFromQuery(metadata: string): ExternalClickTrackPayload {
    const decoded = decodeURIComponent(metadata);
    const json: ExternalClickTrackPayload = JSON.parse(decoded);
    return json;
}

export enum KnownCookie {
    UTM_COOKIE_NAME = 'spinachSource',
    ANONYMOUS_UUID_COOKIE_NAME = 'anonymousUserId',
    CELLO_REFERRER_COOKIE_NAME = 'cello-referral',
    CELLO_UCC_QUERY_PARAM_NAME = 'ucc',
    SPINACH_REFERRER_COOKIE_NAME = 'referrer',
}

export const UTM_COOKIE_NAME = KnownCookie.UTM_COOKIE_NAME;
export const CELLO_COOKIE_NAME = KnownCookie.CELLO_REFERRER_COOKIE_NAME;
export const ANONYMOUS_USER_ID_COOKIE_NAME = KnownCookie.ANONYMOUS_UUID_COOKIE_NAME;
