import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import React from 'react';

import { DashboardSection } from '@spinach-shared/constants';
import { ClientEventType, MeetingSelection } from '@spinach-shared/types';

import { useActivityTracking, useClickTracking, useGlobalAiDashboard } from '../../../../..';
import { GlobalSearchUI } from './GlobalSearchIU';
import { GlobalSearchDetailedMatch, SearchResult } from './globalSearchTypes';
import { getDetailedMatchesForMeeting, getMatchesForMeeting } from './searchUtils';
import { useGlobalSearchData } from './useGlobalSearchData';

export const minSearchTermLength = 3;

export const GlobalSearch = ({
    meetings,
    hideRequestCallback,
    onMeetingSelected,
}: {
    meetings: MeetingSelection[];
    hideRequestCallback: () => void;
    onMeetingSelected: (botId: string, section: DashboardSection) => void;
}) => {
    const { data, isError, isLoading } = useGlobalSearchData(meetings);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [termUsedForSearch, setTermUsedForSearch] = useState<string | undefined>(undefined);
    const selectedResultRef = useRef<SearchResult | null>(null);
    const [detailedMatches, setDetailedMatches] = useState<GlobalSearchDetailedMatch[]>([]);
    const trackActivity = useActivityTracking();

    const { setToastText } = useGlobalAiDashboard();
    useEffect(() => {
        if (isError) {
            setToastText('Error fetching data. Please try again later.');
            trackActivity(ClientEventType.AIDashboardActivity, 'error fetching data for search');
        }
    }, [isError, setToastText, trackActivity]);

    const trackClick = useClickTracking();
    const handleSearch = React.useCallback(
        debounce((term: string) => {
            if (!term || !data) {
                setSearchResults([]);
                setTermUsedForSearch(term);
                return;
            }
            if (term.trim().length < minSearchTermLength) {
                setSearchResults([]);
                setTermUsedForSearch(term);
                return;
            }

            trackClick(ClientEventType.AIDashboardClick, 'searching term', { termSize: term.length });

            const results: SearchResult[] = data
                .map(({ botId, transcript, summary, createdAt }) => ({
                    botId,
                    matches: getMatchesForMeeting({ transcript, summary, term }),
                    createdAt: createdAt,
                    meetingTitle: summary.meetingTitle,
                }))
                .filter((result) => result.matches > 0)
                .sort((a, b) => {
                    const dateA = new Date(a.createdAt);
                    const dateB = new Date(b.createdAt);
                    return dateB.getTime() - dateA.getTime(); // Sort by descending date
                });
            const currentSelectedResult = selectedResultRef.current;
            const matchingResult = currentSelectedResult
                ? results.find((r) => r.botId === currentSelectedResult.botId)
                : undefined;
            let userHadEnoughTimeToReadTheSelectedResult = true;
            if (currentSelectedResult?.timeOfSelection && Date.now() - currentSelectedResult.timeOfSelection < 1500) {
                userHadEnoughTimeToReadTheSelectedResult = false;
            }
            if (matchingResult && userHadEnoughTimeToReadTheSelectedResult) {
                if (currentSelectedResult !== matchingResult) {
                    handleResultClick(matchingResult, term, currentSelectedResult?.timeOfSelection); // number of results can change
                }
            } else {
                if (results.length > 0) {
                    handleResultClick(results[0], term);
                } else {
                    selectedResultRef.current = null;
                }
            }

            setSearchResults(results.slice(0, 30)); // limit number of results
            setTermUsedForSearch(term);
        }, 300),
        [data, trackClick]
    );

    useEffect(() => {
        handleSearch(searchTerm);
        // only run when term changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm, /* effect dep */ data]);

    const handleResultClick = (
        result: { botId: string; matches: number; createdAt: Date; meetingTitle: string },
        latestSearchTerm = searchTerm,
        timeOfSelection: number = Date.now()
    ) => {
        selectedResultRef.current = {
            ...result,
            timeOfSelection,
        };
        const selectedData = data?.find((d) => d.botId === result.botId);
        if (selectedData && selectedData.transcript) {
            setDetailedMatches(getDetailedMatchesForMeeting(selectedData, latestSearchTerm));
        }
    };

    return (
        <GlobalSearchUI
            meetingCount={data?.length ?? 0}
            isSearching={termUsedForSearch !== searchTerm || isLoading}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            searchResults={searchResults}
            handleResultClick={handleResultClick}
            selectedResult={selectedResultRef.current}
            detailedMatches={detailedMatches}
            handleMatchClick={(botId, section) => onMeetingSelected(botId, section)}
            hideRequestCallback={hideRequestCallback}
        />
    );
};
