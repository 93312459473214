import { useState } from 'react';
import { useEffect } from 'react';

import { demoBotId } from '@spinach-shared/constants';
import { ClientEventType, FeatureToggle } from '@spinach-shared/types';

import { useExperienceTracking, useGlobalAiDashboard, useGlobalAuthedUser, useWindowSize } from '../..';

export function useGlobalSearchShortcut(botId: string) {
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();
    const { width } = useWindowSize();

    const { setIsSearchVisible, isSearchVisible: searchVisible } = useGlobalAiDashboard();

    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

    const tooSmallForSearch = width < 1000;

    const isSearchVisible =
        !tooSmallForSearch && user.featureToggles[FeatureToggle.GlobalSearch] && botId !== demoBotId;

    useEffect(() => {
        if (isSearchVisible) {
            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.key === 'k' && (isMac ? event.metaKey : event.ctrlKey) && !searchVisible && isSearchVisible) {
                    event.preventDefault();
                    setIsSearchVisible(true);
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Search Shortcut',
                    });
                }
            };
            document.addEventListener('keydown', handleKeyDown);

            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [isMac, searchVisible, isSearchVisible, track]);

    return { isSearchVisible, isMac };
}
