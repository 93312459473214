import { Modal } from '@material-ui/core';

import { ClientEventType, MeetingSelection } from '@spinach-shared/types';

import { deleteMeetingNotes, useGlobalAiDashboard } from '../../../..';
import { GlobalModal } from '../../../atoms';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalModal } from '../../../hooks';
import { BodyRegular, BodyRegularOnboard, ResponsiveModalTitle } from '../../../styles';
import { PrimaryButton } from '../../stand-up';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Row, Spacing } from '../framing';

export function ConfirmDeleteMeetingModal({ selection }: { selection: MeetingSelection }) {
    const [user] = useGlobalAuthedUser();
    const [globalModal, setGlobalModal] = useGlobalModal();
    const {
        setToastText,
        state: { isLoadingMeetingNotesDeletion },
        setState,
    } = useGlobalAiDashboard();

    const track = useExperienceTracking();

    const closeModal = () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Close Confirm Delete Meeting',
        });
        setGlobalModal(null);
    };

    if (!user) {
        return <></>;
    }

    return (
        <Modal open={globalModal === GlobalModal.ConfirmDeleteMeeting} onClose={closeModal}>
            <SpinachModalContent onClose={closeModal} style={{ overflow: 'hidden', minHeight: 'unset' }}>
                <Column centered>
                    <Row centered>
                        <ResponsiveModalTitle>Delete these meeting notes?</ResponsiveModalTitle>
                    </Row>
                    <Spacing factor={1 / 3} />
                </Column>

                <Column style={{ alignItems: 'start' }}>
                    <BodyRegularOnboard>
                        This meeting history and associated data will be <b>permanently deleted for all recipients.</b>{' '}
                        This includes summary, action items, transcripts, and video. Are you sure?
                    </BodyRegularOnboard>
                    <Spacing factor={1 / 3} />
                </Column>
                <Spacing factor={1.5} />
                <PrimaryButton
                    title="Delete meeting notes"
                    isLoading={isLoadingMeetingNotesDeletion}
                    onClick={async () => {
                        setState((updatedState) => ({
                            ...updatedState,
                            isLoadingMeetingNotesDeletion: true,
                        }));

                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Confirm Delete Meeting Notes',
                        });

                        try {
                            await deleteMeetingNotes(selection.botId);
                            setState((updatedState) => ({
                                ...updatedState,
                                lastRefreshedHistory: new Date().toISOString(),
                            }));

                            setToastText(`Meeting notes for ${selection.title} successfully deleted.`);
                        } catch (error) {
                            setToastText('Something went wrong here. Please reach out if this persists.');
                        } finally {
                            setState((updatedState) => ({
                                ...updatedState,
                                isLoadingMeetingNotesDeletion: false,
                            }));
                        }

                        closeModal();
                    }}
                />
                <Spacing factor={1 / 3} />
                <SecondaryButton title="Cancel" onClick={closeModal} disabled={isLoadingMeetingNotesDeletion} />
            </SpinachModalContent>
        </Modal>
    );
}
