import { MeetingStatus, NotificationCode, TicketSource } from '.';
import { OmniboxSearchOptions } from './OmniboxSearch';
import { ClientPlatform } from './Platform';
import { AgendaItemSource, MeetingFormat, Sentiment, SpinachUpdateType } from './Series';
import { StandupFormat, StandupFrequencyPerWeek } from './Survey';
import { FeatureFlagSet, FeatureFlagValue, FeatureToggle, UserMetadata } from './User';

export enum ClientEventType {
    CopySummaryClick = 'CopySummaryClick',
    CopyStandupLinkClicked = 'CopyStandupLinkClicked',
    OpenScheduleModalFromSettingsClick = 'OpenScheduleModalFromSettingsClick',

    AIDashboardClick = 'AIDashboardClick',
    AIDashboardActivity = 'AIDashboardActivity',

    DesktopClientClick = 'DesktopClientClick',
    // new go-to event classes for anything onboarding not on dashboard
    OnboardingClick = 'OnboardingClick',
    OnboardingActivity = 'OnboardingActivity',

    LoginPageClick = 'LoginPageClick',
    LoginPageActivity = 'LoginPageActivity',

    AIFacilitationClick = 'AIFacilitationClick',
    AIFacilitationActivity = 'AIFacilitationActivity',

    AgentUxClick = 'AgentUxClick',
    AgentUxActivity = 'AgentUxActivity',

    VideoAgentActivity = 'VideoAgentActivity',

    UserRedirectedFromSlackUrl = 'UserRedirectedFromSlackUrl',
    UserRedirectedFromAiEmail = 'UserRedirectedFromAiEmail',
    UserRedirectedFromVideoChatMessage = 'UserRedirectedFromVideoChatMessage',
    UserReferredFromOnboardingSlackConnect = 'UserReferredFromOnboardingSlackConnect',

    BackToDashboardClick = 'BackToDashboardClick',
    StandupSettingsSubmenuClick = 'StandupSettingsSubmenuClick',
    OpenCustomizeStandupSettingsClick = 'OpenCustomizeStandupSettingsClick',
    BackButtonInSettingsClick = 'BackButtonInSettingsClick',

    IntegrationsButtonClick = 'IntegrationsButtonClick',
    CloseIntegrationsModalClick = 'CloseIntegrationsModalClick',

    CopyFilteredSummaryClick = 'CopyFilteredSummaryClick',
    SummaryFilterParticipantClick = 'SummaryFilterParticipantClick',
    SummaryFilterDateClick = 'SummaryFilterDateClick',
    SummaryFilterClear = 'SummaryFilterClear',

    ClickShuffleParticipants = 'ClickShuffleParticipants',

    InviteViaSlackClick = 'InviteViaSlackClick',
    InviteViaSlackModalViewed = 'InviteViaSlackModalViewed',

    ReceivedGeneratedSummaryResults = 'ReceivedGeneratedSummaryResults',
    CopiedGeneratedSummaryClick = 'CopiedGeneratedSummaryClick',
    UserLandedOnIntegrateJiraForSummary = 'UserLandedOnIntegrateJiraForSummary',
    UserLandedOnGenerateSummaryPage = 'UserLandedOnGenerateSummaryPage',
    UserSelectedProjectForGeneration = 'UserSelectedProjectForGeneration',
    UserSelectedJiraUserForGeneration = 'UserSelectedJiraUserForGeneration',
    SpinachLogoClick = 'SpinachLogoClick',
    ChangedActivityWindowDate = 'ChangedActivityWindowDate',
    GenerateSummaryClick = 'GenerateSummaryClick',
    SpinachMarketingSiteClick = 'SpinachMarketingSiteClick',

    SupportTabClicked = 'SupportTabClicked',

    PayUpCalloutDisplayed = 'PayUpCalloutDisplayed',
    PayUpCalloutEngageClick = 'PayUpCalloutEngageClick',
    ClickUpgradePayURL = 'ClickUpgradePayURL',

    GenericCalloutDisplayed = 'GenericCalloutDisplayed',
    GenericCalloutEngageClick = 'GenericCalloutEngageClick',
    GenericCalloutCloseClick = 'GenericCalloutCloseClick',

    ShareSeriesModalClosed = 'ShareSeriesModalClosed',
    SubscribeSeriesOutputModalClosed = 'SubscribeSeriesOutputModalClosed',
    OpenSubscribeSeriesOutputModalClick = 'OpenSubscribeSeriesOutputModalClick',
    SendOptionsButtonClick = 'SendOptionsButtonClick',
    AddSummaryOutputSubscription = 'AddSummaryOutputSubscription',
    RemoveSubscribedOutputClick = 'RemoveSubscribedOutputClick',

    UserLandedOnEmailEntryView = 'UserLandedOnEmailEntryView',
    NewCompanyAssociation = 'NewCompanyAssociation',
    UserSubmittedEmail = 'UserSubmittedEmail',
    UserStartedTypingEmail = 'UserStartedTypingEmail',
    UserEmailGoogleVerifySuccess = 'UserEmailGoogleVerifySuccess',
    UserEmailGoogleVerifySuccessBackend = 'UserEmailGoogleVerifySuccessBackend',
    UserEmailGoogleVerifyFailure = 'UserEmailGoogleVerifyFailure',
    UserEmailMicrosoftVerifyFailure = 'UserEmailMicrosoftVerifyFailure',
    UserClickSignInWithGoogle = 'UserClickSignInWithGoogle',
    UserClickCalendarIntegrate = 'UserClickCalendarIntegrate',

    SSOAttemptDidNotRespond = 'SSOAttemptDidNotRespond',
    SSOAttempt = 'SSOAttempt',

    AddChromeExtentionClick = 'AddChromeExtentionClick',

    UserStartedAiOnboardingFlow = 'UserStartedAiOnboardingFlow',
    UserClickCalendarDaySelector = 'UserClickCalendarDaySelector',
    UserClickCalendarViewMore = 'UserClickCalendarViewMore',
    UserClickCalendarEventDetails = 'UserClickCalendarEventDetails',
    UserChangedCalendarEventStatus = 'UserChangedCalendarEventStatus',
    UserAddedCalendarEventToCheckIn = 'UserAddedCalendarEventToCheckIn',

    UserSelectedMood = 'UserSelectedMood',
    UserAddedMoodDetails = 'UserAddedMoodDetails',
    UserMoodSubmitted = 'UserMoodSubmitted',
    UserClickMoodRedirect = 'UserClickMoodRedirect',

    UserAddResolverDetails = 'UserAddedResolverDetails',

    UserClickedLogOut = 'UserClickedLogOut',

    UserSubmittedStandupTimes = 'UserSubmittedStandupTimes',
    UserCreatedStandupSchedule = 'UserCreatedStandupSchedule',
    UserEditedStandupSchedule = 'UserEditedStandupSchedule',

    AnonymousUserCopiedSpinachLink = 'AnonymousUserCopiedSpinachLink',
    AnonymousUserCreated = 'AnonymousUserCreated',

    ToggleDemoModeClicked = 'ToggleDemoModeClicked',
    ConfirmLeaveDemoModeClick = 'ConfirmLeaveDemoModeClick',
    ContinuePracticeRoundClick = 'ContinuePracticeRoundClick',
    DismissPracticeRoundBannerClick = 'DismissPracticeRoundBannerClick',
    UserClosedDemoGuide = 'UserClosedDemoGuide',
    DemoGuideOpened = 'DemoGuideOpened',
    UserHoveredDemoStepAndRevealedGuide = 'UserHoveredDemoStepAndRevealedGuide',
    AnonymousUserClickedSignUpFromDemo = 'AnonymousUserClickedSignUpFromDemo',
    ClickDemoContextModal = 'ClickDemoContextModal',
    ClickLetsGoButtonInLobby = 'ClickLetsGoButtonInLobby',
    UserClickedDemoSlackView = 'UserClickedDemoSlackView',

    MultilineButtonClick = 'MultilineButtonClick',
    UserCreatedMultilineItem = 'UserCreatedMultilineItem',
    UserRemovedMultilineItem = 'UserRemovedMultilineItem',
    DemoModalInfoHover = 'DemoModalInfoHover',
    DemoTooltipHover = 'DemoTooltipHover',
    PracticeRoundModalOpen = 'PracticeRoundModalOpen',
    UserClickStartPracticeRound = 'UserClickStartPracticeRound',
    UserClickExitPracticeRound = 'UserClickExitPracticeRound',
    UserClickImReadyPracticeRound = 'UserClickImReadyPracticeRound',
    UserClickInviteTeammates = 'UserClickInviteTeammates',
    UserClickTryAnotherPracticeRound = 'UserClickTryAnotherPracticeRound',

    UserClickedCancelAccountChanges = 'UserClickedCancelAccountChanges',
    UserClickedCanceledFirstNameChange = 'UserClickedCancelFirstNameChange',
    UserClickedCanceledLastNameChange = 'UserClickedCancelLastNameChange',
    UserClickedConfirmedAccountChanges = 'UserClickedConfirmedAccountChanges',
    UserClickedConfirmedFirstNameChange = 'UserClickedConfirmedFirstNameChange',
    UserClickedConfirmedLastNameChange = 'UserClickedConfirmedLastNameChange',
    UserAccountCancelClick = 'UserAccountCancelClick',
    UserClickedNameChange = 'UserClickedNameChange',
    UserClickedScribeUserSettings = 'UserClickedScribeUserSettings',
    UserLandedOnCodeEntryView = 'UserLandedOnCodeEntryView',
    UserSubmittedCode = 'UserSubmittedCode',
    UserFailedToProceedFromCodeEntryView = 'UserFailedToProceedFromCodeEntryView',
    UserSucceededToProceedFromCodeEntryView = 'UserSucceededToProceedFromCodeEntryView',
    UserAttemptedToResendEmailCode = 'UserAttemptedToResendEmailCode',
    ResentEmailCodeSuccess = 'ResentEmailCodeSuccess',
    ResentEmailCodeFailed = 'ResentEmailCodeFailed',
    UserClickedRetypeEmailButton = 'UserClickedRetypeEmailButton',

    PersonalPageSubmitted = 'PersonalPageSubmitted',
    UserLandedOnPersonalPage = 'UserLandedOnPersonalPage',
    IntegrationPageSubmitted = 'IntegrationPageSubmitted',

    SurveyResultsCompleted = 'SurveyResultsCompleted',
    SurveyResultsUpdated = 'SurveyResultsUpdated',

    ScheduleMeetingClick = 'ScheduleMeetingClick',

    UserLandedOnCreateOrJoinView = 'UserLandedOnCreateOrJoinView',
    UserSubmittedDemoView = 'UserSubmittedDemoView',
    UserClickedBackToDemoView = 'UserClickedBackToDemoView',
    UserLandedOnInviteTeamView = 'UserLandedOnInviteTeamView',

    UserLandedOnNamingFirstStandupView = 'UserLandedOnNamingFirstStandupView',
    UserSubmittedStandupName = 'UserSubmittedStandupName',

    UserLandedOnCelebrateView = 'UserLandedOnCelebrateView',
    UserLandedOnPracticeRoundContext1View = 'UserLandedOnPracticeRoundContext1View',

    UserSubmittedCelebrateView = 'UserSubmittedCelebrateView',
    UserSubmittedPracticeRoundContext1View = 'UserSubmittedPracticeRoundContext1View',

    UserLandedOnJoinStandupView = 'UserLandedOnJoinStandupView',
    UserRedirectingFromJoinFlowToStandup = 'UserRedirectingFromJoinFlowToStandup',

    OnboardingCompleted = 'OnboardingCompleted',

    UserAssociatedWithSeries = 'UserAssociatedWithSeries',

    InviteButtonClicked = 'InviteButtonClicked',
    IcebreakerClicked = 'IcebreakerClicked',
    IcebreakerLockedIn = 'IcebreakerLockedIn',
    AddIcebreakerResponseButtonClicked = 'AddIcebreakerResponseButtonClicked',

    MeditationAdded = 'MeditationAdded',
    MeditationRemoved = 'MeditationRemoved',
    MeditationTooltipHovered = 'MeditationTooltipHovered',

    NavOpenClick = 'NavOpenClick',
    NavOpenWithTeamTopicsClick = 'NavOpenWithTeamTopicsClick',
    NavOpenWithMyUpdatesClick = 'NavOpenWithMyUpdatesClick',
    NavCloseButtonClick = 'NavCloseClick',
    NavCloseWithTeamTopicsClick = 'NavCloseWithTeamTopicsClick',
    NavCloseWithMyUpdatesClick = 'NavCloseWithMyUpdatesClick',
    NavCloseOnMyTurn = 'NavCloseOnMyTurn',
    NavTabSwitch = 'NavTabSwitch',

    CheckContextItem = 'CheckContextItem',

    ContextItemClick = 'ContextItemClick',
    ContextItemsShown = 'ContextItemsShown',
    TeamTopicPrioritizePageShown = 'TeamTopicPrioritizePageShown',

    UserPastedMultilineUpdate = 'UserPastedMultilineUpdate',

    SummaryViewPageShown = 'SummaryViewPageShown',

    AttachmentButtonClick = 'AttachmentButtonClick',

    ReactionRemoved = 'ReactionRemoved',
    ReactionAdded = 'ReactionAdded',
    ReactionButtonClick = 'ReactionButtonClick',
    ReactionShowMoreButtonClick = 'ReactionShowMoreButtonClick',

    DiscussionReaction = 'DiscussionReaction',
    DiscussionReactionMenuClick = 'DiscussionReactionMenuClick',

    OpenRolloverDrawerClick = 'OpenRolloverDrawerClick',
    CheckRolloverItem = 'CheckRolloverItem',
    ConfirmRolloverUpdatesToSectionClick = 'ConfirmRolloverUpdatesToSectionClick',
    JiraRolloverExpansionClick = 'JiraRolloverExpansionClick',
    RegularItemRolloverExpansionClick = 'RegularItemRolloverExpansionClick',

    JiraIssueHasNoTitle = 'JiraIssueHasNoTitle',

    UserOptedEmailIn = 'UserOptedEmailIn',
    UserLandedOnContactConfirmationPage = 'UserLandedOnContactConfirmationPage',

    UserSettingsAccessClick = 'UserSettingsAccessClick',
    UserAuthorizeSlackClick = 'UserAuthorizeSlackClick',
    TeamDoesNotUseSlackClick = 'TeamDoesNotUseSlackClick',
    OpenSlackSettingsSubviewClick = 'OpenSlackSettingsSubviewClick',
    SelectSlackChannelInSettingsClick = 'SelectSlackChannelInSettingsClick',
    ClearSlackChannelInSettingsClick = 'ClearSlackChannelInSettingsClick',
    HowToConnectSlackPrivateChannelClick = 'HowToConnectSlackPrivateChannelClick',
    OpenScheduleModalFromSlackSettings = 'OpenScheduleModalFromSlackSettings',
    RemoveSlackIntegrationClick = 'RemoveSlackIntegrationClick',
    RemoveSlackIntegrationConfirmClick = 'RemoveSlackIntegrationConfirmClick',
    CancelDeleteSlackIntegrationClick = 'CancelDeleteSlackIntegrationClick',

    UserSelectedSentiment = 'UserSelectedSentiment',

    UserAuthorizeJiraClick = 'UserAuthorizeJiraClick',
    UserAuthorizeGoogleCalendarClick = 'UserAuthorizeGoogleCalendarClick',
    UserAuthorizeGoogleDriveClick = 'UserAuthorizeGoogleDriveClick',
    CalendarMeetingItemClick = 'CalendarMeetingItemClick',
    UserClickJiraTicketViewMore = 'UserClickJiraTicketViewMore',
    UserClickJiraStatusViewMore = 'UserClickJiraStatusViewMore',
    JiraIssueSelected = 'JiraIssueSelected',
    JiraModalOpen = 'JiraModalOpen',
    UserClickJiraBoardExpansion = 'UserClickJiraBoardExpansion',
    UserClickJiraBoardNavigation = 'UserClickJiraBoardNavigation',
    UserClickJiraIssue = 'UserClickJiraIssue',
    OnIntegrateJiraCalloutClick = 'OnIntegrateJiraCalloutClick',
    OnIntegrateJiraCalloutCloseClick = 'OnIntegrateJiraCalloutCloseClick',
    JiraIntegrationCalloutDisplayed = 'JiraIntegrationCalloutDisplayed',
    JiraMagicInputCalloutDisplayed = 'JiraMagicInputCalloutDisplayed',
    UserRemovedJiraTicket = 'UserRemovedJiraTicket',
    OpenJiraSettingsSubviewClick = 'OpenJiraSettingsSubviewClick',
    OpenGoogleCalendarSettingsSubviewClick = 'OpenGoogleCalendarSettingsSubviewClick',
    OpenGoogleDriveSettingsSubviewClick = 'OpenGoogleDriveSettingsSubviewClick',
    DeleteJiraIntegrationClick = 'DeleteJiraIntegrationClick',
    DeleteJiraIntegrationConfirmClick = 'DeleteJiraIntegrationConfirmClick',
    CancelJiraDeleteClick = 'CancelJiraDeleteClick',

    SpinachReferralCalloutDisplayed = 'SpinachReferralCalloutDisplayed',
    SpinachReferralCalloutEngageClick = 'SpinachReferralCalloutEngageClick',
    SpinachReferralCalloutCloseClick = 'SpinachReferralCalloutCloseClick',
    SpinachReferralModalClosed = 'SpinachReferralModalClosed',
    ReferralEmailSubmitted = 'ReferralEmailSubmitted',
    ReferralLinkCopied = 'ReferralLinkCopied',
    SendReferralLinkOnAnotherPlatformClicked = 'SendReferralLinkOnAnotherPlatformClicked',

    AsanaIssueSelected = 'AsanaIssueSelected',
    UserAuthorizeAsanaClick = 'UserAuthorizeAsanaClick',
    AsanaIntegrationCalloutDisplayed = 'AsanaIntegrationCalloutDisplayed',
    OnIntegrateAsanaCalloutClick = 'OnIntegrateAsanaCalloutClick',
    OnIntegrateAsanaCalloutCloseClick = 'OnIntegrateAsanaCalloutCloseClick',
    OpenAsanaSettingsSubviewClick = 'OpenAsanaSettingsSubviewClick',
    DeleteAsanaIntegrationClick = 'DeleteAsanaIntegrationClick',
    DeleteAsanaIntegrationConfirmClick = 'DeleteAsanaIntegrationConfirmClick',
    CancelDeleteAsanaIntegrationClick = 'CancelDeleteAsanaIntegrationClick',

    OpenSettingsSubviewClick = 'OpenSettingsSubviewClick',

    DisplayAllOutAsyncBanner = 'DisplayAllOutAsyncBanner',

    SeriesSettingsOpened = 'SeriesSettingsOpened',
    SavedRoundtableCustomization = 'SavedRoundtableCustomization',
    SeriesSettingsClosedWithoutSaving = 'SeriesSettingsClosedWithoutSaving',

    RecentCheckInHovered = 'RecentCheckInHovered',

    HiddenItemStatusChanged = 'HiddenItemStatusChanged',
    SharedItemStatusChange = 'SharedItemStatusChange',
    UserArchivedItem = 'UserArchivedItem',
    OnUnarchiveItem = 'OnUnarchiveItem',

    UserInitiateOmnibox = 'UserInitiateOmnibox',
    UserSelectedOmniboxItem = 'UserSelectedOmniboxItem',
    NoOmniboxItemsFound = 'NoOmniboxItemsFound',
    UserSearchedForSpecificTicketNumber = 'UserSearchdForSpecificTicketNumber',

    ZoomAppMarketplaceIntegrationsClick = 'ZoomAppMarketplaceIntegrationsClick',

    UpdateEdited = 'UpdateEdited',

    OnSentimentReasonPickerCalloutClick = 'OnSentimentReasonPickerCalloutClick',
    SentimentReasonPickerCalloutDisplayed = 'SentimentReasonPickerCalloutDisplayed',

    OnRegularItemSentimentPickerCalloutClick = 'OnRegularItemSentimentPickerCalloutClick',
    RegularItemSentimentPickerCalloutDisplayed = 'RegularItemSentimentPickerCalloutDisplayed',

    UserNavigatedUsingProgressElement = 'UserNavigatedUsingProgressElement',
    ConfirmDisconnectGoogleCalendarIntegrationClick = 'ConfirmDisconnectGoogleCalendarIntegrationClick',
    DisconnectGoogleCalendarIntegrationClick = 'DisconnectGoogleCalendarIntegrationClick',
    CancelDeleteGoogleCalendarIntegrationClick = 'CancelDeleteGoogleCalendarIntegrationClick',
    RefreshCalendarMeetingsClick = 'RefreshCalendarMeetingsClick',
    GoogleCalendarIntegrationOnboardingContinueClick = 'GoogleCalendarIntegrationOnboardingContinueClick',
    UserGoogleCodeVerifySuccess = 'UserGoogleCodeVerifySuccess',
    UserMicrosoftTokensVerifySuccess = 'UserMicrosoftTokensVerifySuccess',
    UserEmailGoogleVerifyCodeSuccessBackend = 'UserEmailGoogleVerifyCodeSuccessBackend',
    UserMicrosoftTokensVerifySuccessBackend = 'UserMicrosoftTokensVerifySuccessBackend',
    UserLoggedInWithMicrosoftSignIn = 'UserLoggedInWithMicrosoftSignIn',
    UserAuthorizeLinearClick = 'UserAuthorizeLinearClick',
    OpenLinearSettingsSubviewClick = 'OpenLinearSettingsSubviewClick',
    DeleteLinearIntegrationClick = 'DeleteLinearIntegrationClick',
    DeleteLinearIntegrationConfirmClick = 'DeleteLinearIntegrationConfirmClick',
    CancelLinearDeleteClick = 'CancelLinearDeleteClick',

    UserAuthorizeClickUpClick = 'UserAuthorizeClickUpClick',
    OpenClickUpSettingsSubviewClick = 'OpenClickUpSettingsSubviewClick',
    DeleteClickUpIntegrationClick = 'DeleteClickUpIntegrationClick',
    DeleteClickUpIntegrationConfirmClick = 'DeleteClickUpIntegrationConfirmClick',
    CancelClickUpDeleteClick = 'CancelClickUpDeleteClick',

    UserAuthorizeProviderClick = 'UserAuthorizeProviderClick',
    DeleteIntegrationClick = 'DeleteIntegrationClick',
    DeleteIntegrationConfirmClick = 'DeleteIntegrationConfirmClick',
    CancelIntegrationDeleteClick = 'CancelIntegrationDeleteClick',

    UserViewedAboutStep = 'UserViewedAboutStep',
    UserSelectedTeamKind = 'UserSelectedTeamKind',
    UserSelectedTeamRole = 'UserSelectedTeamRole',
    UserSelectedHowDidYouHear = 'UserSelectedHowDidYouHear',
    UserSelectedDepartment = 'UserSelectedDepartment',
    UserSelectedDefaultMeetingLanguageInOnboarding = 'UserSelectedDefaultMeetingLanguageInOnboarding',
    UserSelectedNumberOfPeopleInCompanyInOnboarding = 'UserSelectedNumberOfPeopleInCompanyInOnboarding',
    UserSubmittedAboutStep = 'UserSubmittedAboutStep',
    UserViewedAddSpinachToMeetingsStep = 'UserViewedAddSpinachToMeetingsStep',
    UserToggledMeeting = 'UserToggledMeeting',
    MeetingSuggestionsReceived = 'MeetingSuggestionsReceived',
    UserSubmittedAddSpinachToMeetingStep = 'UserSubmittedAddSpinachToMeetingStep',
    UserSkippedAddSpinachToMeetingsStep = 'UserSkippedAddSpinachToMeetingsStep',
    UserOptedForManualAddSpinachToMeetingsStep = 'UserOptedForManualAddSpinachToMeetingsStep',
    UserWentBackFromAddSpinachToMeetingsStep = 'UserWentBackFromAddSpinachToMeetingsStep',
    UserViewedCalendarPermissionsStep = 'UserViewedCalendarPermissionsStep',
    UserClickedManualToggle = 'UserClickedManualToggle',
    UserClickedIntegrateWithGoogleCalendar = 'UserClickedIntegrateWithGoogleCalendar',
    UserClickedIntegrateWithMicrosoftCalendar = 'UserClickedIntegrateWithMicrosoftCalendar',
    UserSkippedCalendarPermissionsStep = 'UserSkippedCalendarPermissionsStep',
    UserOptedForManualCalendarSetup = 'UserOptedForManualCalendarSetup',
    UserAutoProgressedCalendarPermissionsStep = 'UserAutoProgressedCalendarPermissionsStep',
    UserClickedCalendarPermissionsInviteSpinachManually = 'UserClickedCalendarPermissionsInviteSpinachManually',
    UserSubmittedCalendarPermissionsStep = 'UserSubmittedCalendarPermissionsStep',
    UserWentBackFromCalendarPermissionsStep = 'UserWentBackFromCalendarPermissionsStep',
    UserClickedSecheduleOnboardingSession = 'UserClickedSecheduleOnboardingSession',
    UserClickedSecheduleDemoSessionMidTrial = 'UserClickedSecheduleDemoSessionMidTrial',
    UserViewedManualSpinachSetupStep = 'UserViewedManualSpinachSetupStep',
    UserClickedCopySpinachBotEmail = 'UserClickedCopySpinachBotEmail',
    UserSubmittedManualSpinachSetupStep = 'UserSubmittedManualSpinachSetupStep',
    UserSkippedManualSpinachSetupStep = 'UserSkippedManualSpinachSetupStep',
    UserWentBackFromManualSpinachSetupStep = 'UserWentBackFromManualSpinachSetupStep',
    UserViewedConnectSlackStep = 'UserViewedConnectSlackStep',
    UserWentBackFromConnectSlackStep = 'UserWentBackFromConnectSlackStep',
    UserSkippedConnectSlackStep = 'UserSkippedConnectSlackStep',
    UserAutoProgressedConnectSlackStep = 'UserAutoProgressedConnectSlackStep',
    UserClickedConnectToSlack = 'UserClickedConnectToSlack',
    UserClickedNeedSlackPermissions = 'UserClickedNeedSlackPermissions',
    UserClickedWeDontUseSlack = 'UserClickedWeDontUseSlack',
    UserViewedSlackDefaultsStep = 'UserViewedSlackDefaultsStep',
    UserSubmittedSlackDefaultsStep = 'UserSubmittedSlackDefaultsStep',
    UserSkippedSlackDefaultsStep = 'UserSkippedSlackDefaultsStep',
    UserSelectedSlackDefaultChannel = 'UserSelectedSlackDefaultChannel',
    UserWentBackFromSlackDefaultsStep = 'UserWentBackFromSlackDefaultsStep',
    UserViewedConnectTicketsStep = 'UserViewedConnectTicketsStep',
    UserWentBackFromConnectTicketsStep = 'UserWentBackFromConnectTicketsStep',
    UserSkippedConnectTicketsStep = 'UserSkippedConnectTicketsStep',
    UserSubmittedConnectTicketsStep = 'UserSubmittedConnectTicketsStep',
    UserClickedConnectTickets = 'UserClickedConnectTickets',
    UserViewedConnectKnowledgeBaseStep = 'UserViewedConnectKnowledgeBaseStep',
    UserWentBackFromKnowledgeBaseStep = 'UserWentBackFromKnowledgeBaseStep',
    UserSubmittedKnowledgeBaseStep = 'UserSubmittedKnowledgeBaseStep',
    UserSkippedKnowledgeBaseStep = 'UserSkippedKnowledgeBaseStep',
    UserViewedFinishOnboardingStep = 'UserViewedFinishOnboardingStep',
    UserViewedFinishOnboardingReverseTrialStep = 'UserViewedFinishOnboardingReverseTrialStep',
    UserClickedShareSpinachOnSocial = 'UserClickedShareSpinachOnSocial',
    UserClickedGoToDashboardFromFinished = 'UserClickedGoToDashboardFromFinished',
    UserOpenedWhyDoINeedToConnectMyCalendarTooltip = 'UserOpenedWhyDoINeedToConnectMyCalendarTooltip',
    UserOpenedCalendarConnectSecurityTooltip = 'UserOpenedCalendarConnectSecurityTooltip',
    UserOpenedScheduleADemoSessionTooltip = 'UserOpenedScheduleADemoSessionTooltip',
    UserWentBackFromFinishOnboardingStep = 'UserWentBackFromFinishOnboardingStep',

    SelectNotionPageInSettingsClick = 'SelectNotionPageInSettingsClick',

    UserConfirmedAiDashboardModal = 'UserConfirmedAiDashboardModal',
    UserClosedAiDashboardModal = 'UserClosedAiDashboardModal',

    HelpCenterClick = 'HelpCenterClick',

    KnowledgeBaseParentPageUrlClick = 'KnowledgeBaseParentPageUrlClick',

    AddAICalendarInviteSuggestionAttempt = 'AddAICalendarInviteSuggestionAttempt',
    AddAICalendarInviteSuggestionSuccess = 'AddAICalendarInviteSuggestionSuccess',
    AddAICalendarInviteSuggestionFailed = 'AddAICalendarInviteSuggestionFailed',
    AddAICalendarInviteSuggestionRedirect = 'AddAICalendarInviteSuggestionRedirect',

    MeetingItemRemoveModalShown = 'MeetingItemRemoveModalShown',
    FailedToUpdateMeeting = 'FailedToUpdateMeeting',

    UserPrivacyPolicyCheckboxAction = 'UserPrivacyPolicyCheckboxAction',
    UserOpenedAddToMeetingsStepTrackerTooltip = 'UserOpenedAddToMeetingsStepTrackerTooltip',
    StepTrackerAddToMeetingsRefreshButtonClicked = 'StepTrackerAddToMeetingsRefreshButtonClicked',
    StepTrackerAddToMeetingsSummarySendOptionClicked = 'StepTrackerAddToMeetingsSummarySendOptionClicked',
    StepTrackerAddToMeetingsEditSummariesSwitchToggled = 'StepTrackerAddToMeetingsEditSummariesSwitchToggled',
    UserStartedStepTrackerOnboardingFlow = 'UserStartedStepTrackerOnboardingFlow',
    UserNavigatingToOnboardingStep = 'UserNavigatingToOnboardingStep',
    FailedToGetMeetingSuggestionsInStepTrackerOnboarding = 'FailedToGetMeetingSuggestionsInStepTrackerOnboarding',
    FailedToAddScribeToEventsInStepTrackerOnboarding = 'FailedToAddScribeToEventsInStepTrackerOnboarding',
}

export type ClientExperienceEventPayload =
    | SurveyResultsCompletedPayload
    | SummaryClickPayload
    | NavDrawerClickPayload
    | BaseExperiencEventPayload
    | OpenRolloverDrawerClickPayload
    | ConfirmRolloverUpdatesToSectionClickPayload
    | UserLandedOnContactConfirmationPagePayload
    | UserOptedEmailIn
    | CheckRolloverItemPayload
    | UserSettingsAccessPayload
    | UserAuthorizeSlackClickPayload
    | TeamDoesNotUseSlackClickPayload
    | CheckContextItemPayload
    | ContextItemClickPayload
    | JiraIssueSelectedPayload
    | JiraModalOpenPayload
    | ReactionRemovedPayload
    | ReactionAddedPayload
    | ReactionButtonClickPayload
    | AttachmentButtonClick
    | ReactionShowMoreButtonClickPayload
    | UserAssociatedWithSeriesPayload
    | InviteButtonClickedPayload
    | CopyStandupLinkClickedPayload
    | ShareSeriesModalClosedPayload
    | OnboardingCompletedPayload
    | InterestedMeetingsSubmitted
    | LeaveDemoViewPayload
    | TogglingDemoModePayload
    | StandupTimeAdded
    | ShareSeriesModalClosedPayload
    | UserCreatedStandupSchedulePayload
    | DisplayAllOutAsyncBannerPayload
    | TeamTopicPrioritizePageShownPayload
    | UserInitiateOmniboxPayload
    | UserSelectedOmniboxItemPayload
    | UserSelectedSentimentPayload
    | UserSummaryFiltersPayload
    | JiraIssueHasNoTitlePayload;

export type ClientExperienceEvent = {
    eventType: ClientEventType;
    payload: Record<string, any>;
};

export type JiraIssueHasNoTitlePayload = UserIdentityPayload & {
    IssueId: string;
    IsJiraSearchAllEnabled: boolean;
    IsNumericRegex: boolean;
    IsIssueKeySearch: boolean;
    IsProjectNameSpaceNumericRegex: boolean;
    IsUserSearchingForSpecificIssue: boolean;
    ProjectsInSuggestions?: string;
};

export enum InviteViaSlackActions {
    Integrate = 'Integrate',
    OtherOptions = 'OtherOptions',
    InviteLater = 'Invite Later',
}

export enum AttachmentBarOptions {
    Reaction = 'Reaction',
    Jira = 'Jira',
    MultiLine = 'MultiLine',
    Asana = 'Asana',
    IssueResolution = 'Issue Resolution',
}

export type UserIdentityPayload = {
    UserId: string;
    FirstName?: string;
    LastName?: string;
    Company?: string;
    HowDidYouHearSource?: string;
    UserName: string;
    Email: string;
    RootDomain: string;
    FeatureToggles?: Record<FeatureToggle, FeatureFlagValue>;
    // TODO: This should eventually be required
    UserMetadata?: UserMetadata;
    Platform?: ClientPlatform;
    SessionID?: string;
    IsPersonalUser?: boolean;
    IsHypercontextUser?: boolean;
    CodeEntry?: string;
    IsAffiliateUser?: boolean;
};

export type ButtonActionPayload = {
    ButtonAction: 'Open' | 'Close';
    NumberOfHiddenEmojis: number;
};

export enum SlackActionItemInteraction {
    Approve = 'approve',
    Delete = 'delete',
    Send = 'send',
    Discuss = 'discuss',
    NotApplicable = 'not-applicable',
    Edit = 'edit',
}

export type ReactionEventPayload = {
    SeriesId: string;
    EmojiId: string;
    NumberOfPeopleReacted: number;
    UsersReacted: string[];
};

export type TogglingDemoModePayload = UserIdentityPayload & {
    ToggleAction: 'on' | 'off';
};

export type UserCreatedStandupSchedulePayload = UserIdentityPayload & {
    SeriesId: string;
    NumberOfDaysSubmitted: number;
    MondayStart: string;
    MondayEnd: string;
    TuesdayStart: string;
    TuesdayEnd: string;
    WednesdayStart: string;
    WednesdayEnd: string;
    ThursdayStart: string;
    ThursdayEnd: string;
    FridayStart: string;
    FridayEnd: string;
    SaturdayStart: string;
    SaturdayEnd: string;
    SundayStart: string;
    SundayEnd: string;
    IsReminderEnabled?: boolean;
    ReminderDuration?: number;

    MondayIsChangedForThisWeekOnly: boolean;
    TuesdayIsChangedForThisWeekOnly: boolean;
    WednesdayIsChangedForThisWeekOnly: boolean;
    ThursdayIsChangedForThisWeekOnly: boolean;
    FridayIsChangedForThisWeekOnly: boolean;
    SaturdayIsChangedForThisWeekOnly: boolean;
    SundayIsChangedForThisWeekOnly: boolean;

    MondayMeetingFormat: MeetingFormat;
    TuesdayMeetingFormat: MeetingFormat;
    WednesdayMeetingFormat: MeetingFormat;
    ThursdayMeetingFormat: MeetingFormat;
    FridayMeetingFormat: MeetingFormat;
    SaturdayMeetingFormat: MeetingFormat;
    SundayMeetingFormat: MeetingFormat;

    timezone: string;
};

export type StandupTimeAdded = UserIdentityPayload & {
    SeriesId: string;
    MondayStart: string;
    MondayEnd: string;
    TuesdayStart: string;
    TuesdayEnd: string;
    WednesdayStart: string;
    WednesdayEnd: string;
    ThursdayStart: string;
    ThursdayEnd: string;
    FirstTimeCreation?: boolean;
    FridayStart: string;
    FridayEnd: string;
    SaturdayStart: string;
    SaturdayEnd: string;
    SundayStart: string;
    SundayEnd: string;
    timezone: string;
};

export enum SelectedSentimentAction {
    Added = 'Added',
    Removed = 'Removed',
    Modified = 'Modified',
}

export enum SentimentPickerLocation {
    TeamMood = 'TeamMood',
    JiraMagic = 'JiraMagic',
    UpdateFooter = 'UpdateFooter',
}

export enum EventEmitLocation {
    CheckInDrawer = 'check-in drawer',
}

export type UserSelectedSentimentPayload = UserIdentityPayload & {
    SelectedSentiment: Sentiment;
    Action: SelectedSentimentAction;
};

export type InterestedMeetingsSubmitted = UserIdentityPayload & {
    SelectedStandup: boolean;
    SelectedPlanning: boolean;
    SelectedRetros: boolean;
    SelectedOther: boolean;
};

export type OnboardingCompletedPayload = UserIdentityPayload & {
    WasInterestedInStandups: boolean;
    IsJoiningSeries: boolean;
};

export type NoOmniboxItemsFound = UserIdentityPayload & {
    OmniboxSearchOption: OmniboxSearchOptions;
    SeriesId: string;
};

export type TeamTopicPrioritizePageShownPayload = UserIdentityPayload & {
    SeriesId: string;
};

export type InviteButtonClickedPayload = UserIdentityPayload;

export type CopyStandupLinkClickedPayload = UserIdentityPayload;

export type ShareSeriesModalClosedPayload = UserIdentityPayload & {
    NumberOfUsersAdded: number;
};

export type LeaveDemoViewPayload = UserIdentityPayload & {
    DurationOnViewInSeconds: number;
};

export type UserAssociatedWithSeriesPayload = UserIdentityPayload & {
    SeriesId: string;
};

export type ReactionRemovedPayload = UserIdentityPayload & ReactionEventPayload;

export type ReactionAddedPayload = UserIdentityPayload & ReactionEventPayload;

export type ReactionButtonClickPayload = UserIdentityPayload &
    ButtonActionPayload & {
        SeriesId: string;
        Method: 'click' | 'mouse-off';
    };

export enum ResolverAttachmentButtonAction {
    Remove = 'Remove',
    Add = 'Add',
}

export type AttachmentButtonClick = UserIdentityPayload &
    ButtonActionPayload & {
        AttachmentOption: AttachmentBarOptions;
        SeriesId: string;
        Method: 'click' | 'mouse-off';
    };

export type ResolverAttachmentButtonClick = AttachmentButtonClick & { Action: ResolverAttachmentButtonAction };

export type ReactionShowMoreButtonClickPayload = UserIdentityPayload & ButtonActionPayload & {};

export type BaseExperiencEventPayload = UserIdentityPayload & {
    UserRole: string;
    // TODO: mark as required after this is deployed for some time
    IsDemo?: boolean;
};

export type SummaryClickPayload = BaseExperiencEventPayload & {
    SlackOutputStatus?: NotificationCode;
    MeetingID: string;
};

export type SurveyResultsCompletedPayload = BaseExperiencEventPayload & {
    SurveySubmitted: boolean;
    StandupFrequencyPerWeek?: StandupFrequencyPerWeek;
    StandupFormat?: StandupFormat;
    IsFitForSpinach?: boolean;
};

export type NavDrawerClickPayload = UserIdentityPayload & {
    MeetingStatus: MeetingStatus;
    TimeOpened?: string;
    SeriesId: string;
    MeetingID: string;
    AgendaItemSource?: AgendaItemSource;
    AutoAction?: boolean;
    ContextItemsPopulated?: number;
    ContextItemsAvailable?: number;
    YesterdayItemsPopulated?: number;
    TodayItemsPopulated?: number;
    ChallengeItemsPopulated?: number;
    ParkingLotItemsPopulated?: number;
    Panel?: NavDrawer;
    DrawerAction: `To ${NavDrawer}` | DrawerAction;
};

export enum NavDrawer {
    EditCheckIn = 'EditCheckIn',
    TeamTopics = 'TeamTopics',
    Summaries = 'Summaries',
}

export enum DrawerAction {
    Open = 'Open',
    Close = 'Close',
    ToTeamTopics = 'To Team Topics',
    ToSummaries = 'To Summaries',
    ToEditCheckin = 'To Edit Check-in',
}
export type CheckContextItemPayload = UserIdentityPayload & {
    CheckingAction: 'checking' | 'unchecking';
};

export type ContextItemClickPayload = UserIdentityPayload & {
    MeetingID?: string;
    TicketSource?: TicketSource;
    IsResolverItem: boolean;
    NumberOfSubItems: number;
    YTBUpdateType: SpinachUpdateType;
    YTBItemAddedTo: SpinachUpdateType;
    KeyboardEvent: boolean;
    UpdateId: string;
};

export enum JiraIssueSelectionType {
    Linked = 'linked',
    Suggested = 'suggested',
    RolledOver = 'rolledOver',
}

export type JiraIssueSelectedPayload = Omit<UserIdentityPayload, 'Email' | 'FeatureToggles'> & {
    Email: string;
    SeriesId: string;
    SelectionType: JiraIssueSelectionType;
    FeatureToggles: FeatureFlagSet<FeatureToggle>;
};

export type JiraModalOpenPayload = Omit<UserIdentityPayload, 'Email'> & {
    Email: string;
    SeriesId: string;
};

export type OpenRolloverDrawerClickPayload = BaseExperiencEventPayload;

export type UserLandedOnContactConfirmationPagePayload = Pick<
    BaseExperiencEventPayload,
    'Email' | 'UserId' | 'Platform'
>;

export type UserOptedEmailIn = BaseExperiencEventPayload;

export type ConfirmRolloverUpdatesToSectionClickPayload = BaseExperiencEventPayload & {
    RolloverUpdateType: SpinachUpdateType;
    RolloverCount: number;
};

export type UserSettingsAccessPayload = UserIdentityPayload & {
    ButtonUsed: 'corner' | 'cta' | 'background';
    MeetingStatus: MeetingStatus;
    Action: 'opened' | 'closed';
};

export type UserAuthorizeSlackClickPayload = UserIdentityPayload & {
    MeetingStatus: MeetingStatus;
};

export type TeamDoesNotUseSlackClickPayload = UserIdentityPayload;

export type DisplayAllOutAsyncBannerPayload = UserIdentityPayload;

export type CheckRolloverItemPayload = UserIdentityPayload & {
    UpdateType: SpinachUpdateType;
    RolloverItemDate: string;
    CheckingAction: 'checking' | 'unchecking';
};

export enum OmniboxToggleAction {
    Open = 'Open',
    Close = 'Close',
}

export enum OmniboxToggleTrigger {
    Button = 'Button',
    KeyboardShortcut = 'KeyboardShortcut',
    Automated = 'Automated',
}

export type UserInitiateOmniboxPayload = UserIdentityPayload & {
    Trigger?: OmniboxToggleTrigger;
    Action: OmniboxToggleAction;
    SeriesSlug: string;
    FromCallout?: boolean;
    UserClosedWithoutItemSelection?: boolean;
};

export enum OmniboxItemSelectionTrigger {
    Click = 'Click',
    Enter = 'Enter',
}

export type UserSelectedOmniboxItemPayload = UserIdentityPayload & {
    OmniboxSearchOption: OmniboxSearchOptions;
    UpdateType: SpinachUpdateType;
    Trigger?: OmniboxItemSelectionTrigger;
};

export type UserSummaryFiltersPayload = UserIdentityPayload & {
    SelectedParticipants?: string;
    SelectedDate?: string;
};

export type UpdateEditedPayload = {
    UpdateType: SpinachUpdateType | undefined;
    UpdateAuthorId: string | undefined;
    UpdateEditorId: string;
    UpdateEditorIsAuthor: boolean;
    UpdateId: string | undefined;
    InNavDrawer: boolean | undefined;
    IsSubItem: boolean;
    UpdateCreated: boolean;
    UpdateDeleted: boolean;
};

export type Primitive = string | number | boolean | undefined;
