import { CallMade } from '@material-ui/icons';
import styled from 'styled-components';

import { DashboardSection, INTERCOM_SUPPORT_URL_STANDUP_APP } from '@spinach-shared/constants';
import { ClientEventType } from '@spinach-shared/types';

import { ReactComponent as SpinachAiApp } from '../../../assets/Spinach_AI.svg';
import { ReactComponent as SpinachStandupApp } from '../../../assets/Spinach_Standup.svg';
import { ReactComponent as SpinachLogo } from '../../../assets/logo-spinachio.svg';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalRouting, useWindowSize } from '../../../hooks';
import { useGlobalStoredSeriesList } from '../../../hooks/useGlobalStoredSeriesList';
import { lightTheme } from '../../../styles';
import { URLUtil } from '../../../utils';
import { BootstrapTooltip, Column, Row, Spacing } from '../../common';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { ProfileComponent } from './NavBar';
import { DashboardComponent } from './types';

const SidebarColumn = styled(Column)`
    padding: 20px;
    height: 100%;
    width: calc(100% - 40px);
`;

export function StandupAppSidebar({ activeSection, setActiveSection }: DashboardComponent): JSX.Element {
    const track = useExperienceTracking();
    const { routeToDemoExperience, routeToDashboard, routeToAIDashboard } = useGlobalRouting();
    const [user] = useGlobalAuthedUser();
    const { isDesktopView } = useWindowSize();
    const {
        storedSeriesListState: { storedSeriesList },
    } = useGlobalStoredSeriesList();

    const hasLegacySeries = storedSeriesList.filter((s) => !s.isDemoSeries).some((s) => !s.isScribeSeries);

    const userShouldSeeBothApps =
        (hasLegacySeries || user.isForcedLegacyOnboarding) &&
        !user.isHidingAiDashboard &&
        !user.isHidingStandupAppDashboard;

    const appBarWidth = 68;
    const sidebarWidth = 262;
    const widebarWidth = userShouldSeeBothApps ? appBarWidth + sidebarWidth : sidebarWidth;
    return (
        <Row
            style={{
                width: widebarWidth,
                height: '100%',
            }}
        >
            {userShouldSeeBothApps && isDesktopView ? (
                <Column
                    style={{
                        height: '100%',
                        width: `${appBarWidth}px`,
                        backgroundColor: `#E2E8E8`,
                        zIndex: 1,
                        paddingRight: '5px',
                        paddingLeft: '5px',
                    }}
                >
                    <BootstrapTooltip title={`${user.meetingWord}s`} placement={'right'}>
                        <SpinachStandupApp
                            style={{
                                width: '50px',
                                height: '50px',
                                cursor: 'pointer',
                                transition: 'opacity 0.2s',
                                margin: '10px auto',
                                display: 'block',
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.opacity = '0.7';
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.opacity = '1';
                            }}
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, { ClickedOn: 'Dashboard Sidebar Standup App' });
                                routeToDashboard();
                            }}
                        />
                    </BootstrapTooltip>
                    <BootstrapTooltip title="Spinach AI" placement={'right'}>
                        <SpinachAiApp
                            style={{
                                width: '50px',
                                height: '50px',
                                cursor: 'pointer',
                                transition: 'opacity 0.2s',
                                margin: '10px auto',
                                display: 'block',
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.opacity = '0.7';
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.opacity = '1';
                            }}
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, { ClickedOn: 'Dashboard Sidebar AI App' });
                                routeToAIDashboard();
                            }}
                        />
                    </BootstrapTooltip>
                </Column>
            ) : null}
            <Column
                style={{
                    height: '100%',
                    width: `${sidebarWidth}px`,
                    backgroundColor: `${lightTheme.neutrals.grayLight}`,
                    zIndex: 1,
                    marginRight: '20px',
                }}
            >
                <SidebarColumn>
                    {isDesktopView ? (
                        <SpinachLogo
                            style={{
                                height: isDesktopView ? '35px' : '25px',
                                cursor: 'pointer',
                                marginLeft: '-35px',
                                marginBottom: '20px',
                                marginTop: '5px',
                            }}
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, { ClickedOn: 'Sidebar Logo' });
                                setActiveSection(DashboardSection.Summaries);
                            }}
                        />
                    ) : null}
                    {isDesktopView ? (
                        <>
                            <ProfileComponent />
                            <Spacing factor={1 / 2} />
                        </>
                    ) : null}
                    <SecondaryButton
                        title={`Your ${user.meetingWord}s`}
                        buttonStyles={{ textDecoration: 'none' }}
                        labelStyles={{
                            fontSize: '18px',
                            fontWeight: 700,
                            display: 'flex',
                            width: '100%',
                            textAlign: 'left',
                        }}
                        color={
                            activeSection === DashboardSection.Meetings
                                ? lightTheme.primary.greenLight
                                : lightTheme.primary.midnight
                        }
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Standups Tab' });
                            setActiveSection(DashboardSection.Meetings);
                        }}
                    />
                    <Spacing factor={1 / 2} />
                    <SecondaryButton
                        title="Summaries"
                        buttonStyles={{ textDecoration: 'none' }}
                        labelStyles={{
                            fontSize: '18px',
                            fontWeight: 700,
                            display: 'flex',
                            width: '100%',
                            textAlign: 'left',
                        }}
                        color={
                            activeSection === DashboardSection.Summaries
                                ? lightTheme.primary.greenLight
                                : lightTheme.primary.midnight
                        }
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Summaries Tab' });
                            setActiveSection(DashboardSection.Summaries);
                        }}
                    />
                    <Spacing factor={1 / 2} />
                    <SecondaryButton
                        title="Integrations"
                        buttonStyles={{ textDecoration: 'none' }}
                        labelStyles={{
                            fontSize: '18px',
                            fontWeight: 700,
                            display: 'flex',
                            width: '100%',
                            textAlign: 'left',
                        }}
                        color={
                            activeSection === DashboardSection.Integrations
                                ? lightTheme.primary.greenLight
                                : lightTheme.primary.midnight
                        }
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Integrations Tab' });
                            setActiveSection(DashboardSection.Integrations);
                        }}
                    />
                    <Spacing factor={1 / 2} />
                    <SecondaryButton
                        title="Practice Round"
                        buttonStyles={{ textDecoration: 'none' }}
                        labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                        color={lightTheme.primary.midnight}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Practice Round Tab' });
                            routeToDemoExperience();
                        }}
                        endIcon={
                            <CallMade
                                style={{ fontSize: '16px', marginLeft: '5px', marginBottom: '-2px' }}
                                htmlColor={lightTheme.primary.greenLight}
                            />
                        }
                    />

                    <Spacing factor={1 / 2} />
                    <SecondaryButton
                        title="Help Center"
                        buttonStyles={{ textDecoration: 'none' }}
                        labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                        color={lightTheme.primary.midnight}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Help Center Link' });
                            URLUtil.openURL(INTERCOM_SUPPORT_URL_STANDUP_APP);
                        }}
                        endIcon={
                            <CallMade
                                style={{ fontSize: '16px', marginLeft: '5px', marginBottom: '-2px' }}
                                htmlColor={lightTheme.primary.greenLight}
                            />
                        }
                    />
                </SidebarColumn>
            </Column>
        </Row>
    );
}
