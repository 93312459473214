import { Box } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import React, { useState } from 'react';
import styled from 'styled-components';

import { MeetingChaptersJSON } from '@spinach-shared/types';

import { Column, Row } from '../../../common';

const BodyRegular = styled.div`
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const ChapterContainer = styled(Box)`
    padding: 8px 0;
    margin-bottom: 16px;
    width: 100%;
`;

const ChapterTitle = styled(Row)`
    padding: 6px 16px;
    background: transparent;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    width: 100%;
    box-sizing: border-box;
`;

const PlayButton = styled(PlayArrowIcon)<{ isPlaying?: boolean }>`
    color: ${(props) => (props.isPlaying ? '#35a289' : '#666')};
    visibility: hidden;
    margin-right: 8px;
    flex-shrink: 0;
    position: absolute;
    left: 8px;
    transform: translateX(0);
`;

const SectionItem = styled(Row)<{ isPlaying?: boolean }>`
    padding: 8px 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background: white;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    margin: 4px 0;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    position: relative;

    &:hover {
        background: ${(props) => (props.isPlaying ? 'white' : '#eef9f6')};
        border-color: ${(props) => (props.isPlaying ? '#e0e0e0' : '#35a289')};
        padding-left: 32px;

        ${PlayButton} {
            visibility: visible;
        }
    }

    ${(props) =>
        props.isPlaying &&
        `
        padding-left: 32px;
        ${PlayButton} {
            visibility: visible;
        }
    `}

    & > div:first-child {
        flex: 1;
        min-width: 0;
        margin-right: 16px;
    }
`;

const Duration = styled.span`
    margin-left: 8px;
    color: #666;
    font-size: 14px;
    font-weight: bold;
`;

const ChapterSummary = styled(BodyRegular)<{ isVisible: boolean }>`
    color: #000; // Pink color
    margin-top: ${(props) => (props.isVisible ? '0px' : '0')};
    display: ${(props) => (props.isVisible ? 'block' : 'none')};
    padding: 0 16px;
    white-space: normal;
    overflow: visible;
    text-overflow: initial;
`;

const StyledArrow = styled(({ isExpanded, ...props }) =>
    isExpanded ? <KeyboardArrowUpIcon {...props} /> : <KeyboardArrowDownIcon {...props} />
)`
    color: #000;
    margin-left: 8px;
`;

const TimeStamp = styled(BodyRegular)`
    color: #666;
    font-size: 14px;
    text-decoration: underline;
    flex-shrink: 0;
`;

// Replace it with this updated version that will properly contain the content
const ChaptersWrapper = styled(Column)`
    width: calc(100% - 10px);
    overflow-x: hidden;
    max-width: 600px;
`;

interface MeetingChaptersProps {
    botId?: string;
    seriesId?: string;
    chapters?: MeetingChaptersJSON['chapters'];
    playAtSecond?: (second: number) => void;
    currentTime?: number;
}

const calculateDuration = (startTime: string, endTime: string): string => {
    const startSeconds = timeToSeconds(startTime);
    const endSeconds = timeToSeconds(endTime);

    const durationMinutes = Math.ceil((endSeconds - startSeconds) / 60);
    return `(${durationMinutes} min)`;
};

const timeToSeconds = (time: string): number => {
    const parts = time.split(':').map(Number);
    if (parts.length === 3) {
        // Format: hh:mm:ss
        return parts[0] * 3600 + parts[1] * 60 + parts[2];
    }
    // Format: mm:ss
    return parts[0] * 60 + parts[1];
};

const isTimeInRange = (currentTime: number | undefined, startTime: string, endTime: string): boolean => {
    if (!currentTime) return false;
    const start = timeToSeconds(startTime);
    const end = timeToSeconds(endTime);
    return currentTime >= start && currentTime < end;
};

export const MeetingChapters = ({ chapters, playAtSecond, currentTime }: MeetingChaptersProps) => {
    const [expandedSummaries, setExpandedSummaries] = useState<number[]>([]);

    if (!chapters || chapters.length === 0) {
        return null;
    }

    const toggleSummary = (index: number) => {
        setExpandedSummaries((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
    };

    return (
        <ChaptersWrapper>
            {chapters.map((chapter, index) => {
                const isSummaryExpanded = expandedSummaries.includes(index);
                const duration = calculateDuration(chapter.startTime, chapter.endTime);

                return (
                    <ChapterContainer key={index}>
                        <ChapterTitle onClick={() => toggleSummary(index)}>
                            <Row style={{ alignItems: 'center' }}>
                                {chapter.title}
                                <Duration>{duration}</Duration>
                                <StyledArrow isExpanded={isSummaryExpanded} />
                            </Row>
                        </ChapterTitle>

                        <ChapterSummary isVisible={isSummaryExpanded}>{chapter.summary}</ChapterSummary>
 
                        {chapter.subsections?.map((subsection, subIndex) => {
                            const nextSubsectionStartTime =
                                chapter.subsections && subIndex < chapter.subsections.length - 1
                                    ? chapter.subsections[subIndex + 1].startTime
                                    : chapter.endTime;

                            const isPlaying = isTimeInRange(currentTime, subsection.startTime, nextSubsectionStartTime);

                            return (
                                <SectionItem
                                    key={subIndex}
                                    isPlaying={isPlaying}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        playAtSecond?.(timeToSeconds(subsection.startTime));
                                    }}
                                >
                                    <Row style={{ alignItems: 'center', overflow: 'hidden' }}>
                                        <PlayButton isPlaying={isPlaying} />
                                        <BodyRegular style={{ flex: 1, minWidth: 0 }}>{subsection.title}</BodyRegular>
                                    </Row>
                                    <TimeStamp>{subsection.startTime}</TimeStamp>
                                </SectionItem>
                            );
                        })}
                    </ChapterContainer>
                );
            })}
        </ChaptersWrapper>
    );
};
